import { graphql } from 'gatsby'
import React from 'react'

import img1 from '../../assets/blog/23/1.gif'
import img2 from '../../assets/blog/23/2.png'
import img3 from '../../assets/blog/23/3.gif'
import img4 from '../../assets/blog/23/4.png'
import img5 from '../../assets/blog/23/5.png'
import img6 from '../../assets/blog/23/6.png'
import img7 from '../../assets/blog/23/7.png'
import heroImg from '../../assets/blog/23/hero.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Jak jednoduše zorganizovat rodinný život" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Jak jednoduše zorganizovat rodinný život" />
        <ContentSubPage>
          <div className="w-3/4 mx-auto my-12">
            <img
              src={heroImg}
              alt="Jak jednoduše zorganizovat rodinný život"
              className="object-cover w-full max-h-[600px] rounded-[20px]"
            />
          </div>
          <p>
            Život, obzvláště početnější rodiny, se může zdát jako nekonečný
            kolotoč povinností, úkolů a aktivit. Škola, školka, kroužky,
            návštěvy lékaře, nákupy, úklid, pracovní povinnosti a společenské
            akce - to vše vyžaduje spoustu času, energie a především dobré
            organizace. V dnešní uspěchané době, kdy jsou nároky na jednotlivce
            stále vyšší, je snadné se v tom všem ztratit a na něco důležitého
            zapomenout.
          </p>
          <p>
            Představme si typickou rodinu: děti mají různé kroužky, rodiče se
            snaží skloubit pracovní povinnosti s&nbsp;domácností, blíží se
            kulaté narozeniny babičky či jiná významná rodinná událost a ještě k
            tomu je potřeba zajet s autem do servisu. A to jsme ještě nezmínili
            nákupy, úklid, školní akce a návštěvy lékaře. Jak si s tím vším
            poradit, aniž bychom zapomněli na něco důležitého nebo se úplně
            ztratili v chaosu?
          </p>
          <p>
            Ano, doba je hektická, ale zároveň technologicky pokročilá, takže si
            na trhu můžeme vybrat některou z&nbsp;chytrých aplikací, která nám
            s&nbsp;plánováním úkolů, povinností, ale i volného času bude
            nápomocna. Takovým šikovným pomocníkem, který pomůže udržet všechny
            ty nitky pohromadě, může být například aplikace Flagis, osobní
            organizátor a asistent v jednom. Flagis má intuitivní rozhraní,
            které umožňuje vytvářet a spravovat úkoly a poznámky pro všechny
            aspekty našeho života. Můžeme si vytvořit kategorie jako „Škola“,
            „Školka“, „Kroužky“, „Volnočasové aktivity“, „Lékař“, „Domácnost“,
            „Nákupy“ a mnoho dalších, které odpovídají našim potřebám. Každý
            úkol můžeme označit štítkem, určit jeho důležitost, nastavit
            připomínky nebo přidat přílohy, jako jsou fotky, videa, seznamy
            nákupů, pozvánky na oslavy, recepty nebo zprávy od lékaře.
          </p>
          <p>
            A proč si vybrat právě aplikaci Flagis? V&nbsp;této aplikaci můžete
            snadno třídit úkoly podle jednotlivých členů rodiny nebo typu
            aktivity. Chcete mít přehled o tom, kdo má kdy kroužek, kontrolu u
            lékaře nebo školní akci? Žádný problém, všechno bude přehledně na
            jednom místě. Stačí jen otevřít aplikaci a jedním kliknutím
            zjistíte, co vás ten den čeká. Výhoda aplikace Flagis je, že si
            jeden úkol, událost nebo poznámku můžete zařadit do více kategorií
            (pod více štítků), takže se vám nestane, že jej budete dlouho
            hledat, protože si nepamatujete, do které „složky“ jste si jej
            zrovna uložili.
          </p>
          <p>
            Nákupy, ať už běžné, nebo nákup dárků, se stávají hračkou. Vytvořte
            si kategorii „Nákupy“ a v&nbsp;nich podkategorie jako „Jídlo“,
            „Oblečení“, „Školní potřeby“, „Drogerie“, „Lékárna“, „Domácnost“,
            „Dárky“ atd. Snadno pak zjistíte, co je potřeba nakoupit. Ve Flagisu
            můžete také posílat úkoly ostatním členům rodiny. Takže pokud vám
            doma něco chybí a nemáte čas to koupit, jednoduše pošlete úkol
            partnerovi nebo partnerce, aby to zakoupili cestou z práce.
          </p>
          <p>
            A co takové rodinné oslavy a významná data? Narozeniny, výročí,
            promoce - je toho hodně a je snadné na něco zapomenout. Využitím
            Flagisu se stává organizace rodinných oslav mnohem jednodušší.
            Můžete si vytvořit speciální kategorii &quot;Oslavy&quot; a ke každé
            události přidat poznámku s důležitými detaily nebo připomenutím.
            Aplikace vám pak v pravý čas pošle upozornění, abyste na nic
            nezapomněli.
          </p>
          <p>
            Řídit rodinu, domácnost a pracovní povinnosti dá zabrat a šikovný
            pomocník či asistent se opravdu hodí. S&nbsp;Flagisem je to snadné!
            Budete mít vše pod kontrolou a nic vám neunikne. Ten pocit, když
            máte jistotu, že jste na nic nezapomněli a všechno běží jako po
            drátkách! Nechte se inspirovat našimi ukázkovými Smart View níže.
            Vyzkoušejte Flagis a uvidíte, že budete mít méně stresu a chaosu,
            ale hlavně více času na rodinu, koníčky a odpočinek.
          </p>

          <div className="my-5">
            <a href={img1} target="_blank" rel="noreferrer">
              <img src={img1} alt="1" className="object-contain" />
            </a>
          </div>
          <div className="my-5">
            <a href={img2} target="_blank" rel="noreferrer">
              <img src={img2} alt="2" className="object-contain" />
            </a>
          </div>
          <div className="my-5">
            <a href={img3} target="_blank" rel="noreferrer">
              <img src={img3} alt="2" className="object-contain" />
            </a>
          </div>
          <div className="my-5">
            <a href={img4} target="_blank" rel="noreferrer">
              <img src={img4} alt="2" className="object-contain" />
            </a>
          </div>
          <div className="my-5">
            <a href={img5} target="_blank" rel="noreferrer">
              <img src={img5} alt="2" className="object-contain" />
            </a>
          </div>
          <div className="my-5">
            <a href={img6} target="_blank" rel="noreferrer">
              <img src={img6} alt="2" className="object-contain" />
            </a>
          </div>
          <div className="my-5">
            <a href={img7} target="_blank" rel="noreferrer">
              <img src={img7} alt="2" className="object-contain" />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
